@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-input {
  @apply text-black mt-1 block w-full rounded py-1 px-2 bg-gray-50 border border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}

.theme-error {
  @apply text-red-500;
}

.theme-nav-title {
  @apply text-2xl font-bold print:text-lg;
}

.table-border {
  @apply border text-center text-lg p-2 print:text-xs print:border-black print:px-1 print:py-0;
}

.table-border-yellow {
  @apply table-border border-yellow-400 print:border-black;
}

.table-border-total {
  @apply table-border border-purple-400 print:border-black;
}

.table-border-male {
  @apply table-border border-blue-400 print:border-black;
}

.table-border-female {
  @apply table-border border-pink-400 print:border-black;
}

.logo-drop-shadow {
  -webkit-filter: drop drop-shadow(0 0 2px #ffffff) drop-shadow(0 0 5px #ffffff)
    drop-shadow(0 0 9px #ffffff);
  filter: drop-shadow(0 0 2px #ffffff) drop-shadow(0 0 5px #ffffff)
    drop-shadow(0 0 9px #ffffff);
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.95);
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -webkit-tap-highlight-color: transparent;
  /* 3*/
}
body {
  margin: 0;
}
main {
  display: block;
}
p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: inherit;
  /* 2 */
}
address {
  font-style: inherit;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
abbr[title] {
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: inherit;
  /* 2 */
}
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img,
embed,
object,
iframe {
  vertical-align: bottom;
}
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
}
[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
       appearance: checkbox;
}
[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
       appearance: radio;
}
button,
input {
  /* 1 */
  overflow: visible;
}
button,
select {
  /* 1 */
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}
button[disabled],
[type="button"][disabled],
[type="reset"][disabled],
[type="submit"][disabled] {
  cursor: default;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
select::-ms-expand {
  display: none;
}
option {
  padding: 0;
}
fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  /* 1 */
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
label[for] {
  cursor: pointer;
}
details {
  display: block;
}
summary {
  display: list-item;
}
[contenteditable] {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  text-align: left;
}
td,
th {
  vertical-align: top;
  padding: 0;
}
th {
  text-align: left;
  font-weight: bold;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
/* CSS Custom Properties Around Colors */
:root {
  --black: #000;
  --white: #fff;
  --gray: #666;
  --light-gray: #eee;
  --blue-gray: #cde6ff;
  --main: #50bc13;
  --accent: #f95858;
  --alert: #f95858;
  --red: #f95858;
  --orange: #f79a1f;
  --yellow: #f4ce11;
  --green: #50bc13;
  --sky: #00BEF7;
  --blue: #595FFF;
  --violet: #A052FF;
  --pink: #FF52AE;
  --brown: #4a3117;
  --light-red: #ff7a7a;
  --light-orange: #ffb34f;
  --light-yellow: #f5dc62;
  --light-green: #78cc49;
  --light-sky: #54D1F7;
  --light-blue: #7A7FFF;
  --light-violet: #BC85FF;
  --light-pink: #FF70BC;
  --title: var(--black);
  --text: var(--black);
  --link: var(--black);
  --visited: var(--link);
  --hover: var(--black);
  --text-strong: var(--black);
  --text-discreet: var(--gray);
  --text-border: var(--gray);
  --marker: var(--accent);
  --shadow: rgba(0, 0, 0, 0.05);
  --error: var(--alert);
}
:root ::-moz-selection {
  color: var(--brown);
  background-color: var(--category-color);
}
:root ::selection {
  color: var(--brown);
  background-color: var(--category-color);
}
:root {
  font-size: 14px;
  scroll-behavior: smooth;
  /* CSS Custom Properties without Colors
	// -------------------------------------------------*/
  --main-width: 1200px;
}
br[sp],
br[tl] {
  display: none;
}
br[pc] {
  display: inline;
}
@media screen and (max-width: 1199px) {
  br[sp],
  br[pc] {
    display: none;
  }
  br[tl] {
    display: inline;
  }
}
img {
  width: 100%;
  outline: 0;
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
p {
  /* normalize reset */
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin: 0;
}
p {
  /* custom */
  margin-bottom: 0.5em;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* ==================================================================
	汎用スタイル
================================================================== */
.text p,
[class^=text-] p {
  margin-bottom: 15px;
}
.wrap {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}
.marker {
  background: linear-gradient(transparent 50%, var(--marker) 0%);
}
.note {
  /* 接頭辞1文字つきの注記 */
  font-family: monospace;
  text-indent: -1em;
  padding-left: 1em;
  color: var(--gray);
}
.notice {
  /* 案内表記（赤色表示) */
  color: var(--accent);
}
main {
  margin-top: 36px;
  width: var(--main-width);
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}
[data-ruby] {
  position: relative;
  white-space: nowrap;
}
[data-ruby]::before {
  content: attr(data-ruby);
  position: absolute;
  top: -1.15em;
  left: 50%;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  scale: 0.7;
  translate: -50% 0;
  transform-origin: center center;
  letter-spacing: 0;
}
.pcleadcenter {
  text-align: center;
}
/* ==================================================================
	基本セクション
================================================================== */
.content.standard {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.content.standard > div:not(.memo, .info, .pdf, .movie) {
  width: 100%;
}
main.page,
main.post {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0 60px;
  position: relative;
}
main.page > section,
main.post > section,
main.page > nav,
main.post > nav {
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 -10px 30px 0 rgba(89, 95, 255, 0.15);
}
main.page > section,
main.post > section {
  background-color: rgba(255, 255, 255, 0.95);
}
main.page > nav,
main.post > nav {
  background-color: var(--white);
  overflow: hidden;
}
main.page section.content,
main.post section.content {
  --content-padding-inline: 75px;
  width: 100%;
  flex-shrink: 1;
  padding: 42px var(--content-padding-inline) 60px;
}
main.page section.content .title-content,
main.post section.content .title-content {
  height: auto;
  margin-bottom: 60px;
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--category-color);
  text-align: center;
}
main.page section.content .title-content img,
main.post section.content .title-content img {
  width: auto;
  height: 86px;
}
main.page section.content h1,
main.post section.content h1,
main.page section.content h2,
main.post section.content h2,
main.page section.content h3,
main.post section.content h3,
main.page section.content h4,
main.post section.content h4,
main.page section.content h5,
main.post section.content h5,
main.page section.content h6,
main.post section.content h6 {
  color: var(--category-color);
  font-weight: bold;
  text-align: center;
}
main.page section.content h1,
main.post section.content h1 {
  font-size: 2.7rem;
}
main.page section.content h2,
main.post section.content h2 {
  font-size: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 30px;
}
main.page section.content h2::before,
main.post section.content h2::before,
main.page section.content h2::after,
main.post section.content h2::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: var(--category-light);
}
main.page section.content :not(.memo, .info, .pdf) h2,
main.post section.content :not(.memo, .info, .pdf) h2 {
  margin-bottom: 36px;
}
main.page section.content h3,
main.post section.content h3 {
  font-size: 1.8rem;
}
main.page section.content :not(.memo, .info, .pdf) h3,
main.post section.content :not(.memo, .info, .pdf) h3 {
  margin-bottom: 24px;
}
main.page section.content > div,
main.post section.content > div {
  /* .content直下のdiv */
  margin-bottom: 60px;
}
main.page section.content > div:last-of-type,
main.post section.content > div:last-of-type {
  margin-bottom: 0;
}
main.page .nav-sub,
main.post .nav-sub {
  width: 240px;
  flex-shrink: 0;
}
main.page .nav-sub a,
main.post .nav-sub a {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  padding: 30px 30px;
  border-bottom: 1px solid var(--category-gray);
  text-align: center;
}
main.page .nav-sub a:last-of-type,
main.post .nav-sub a:last-of-type {
  border-bottom: 0;
}
main.page .nav-sub a:hover,
main.post .nav-sub a:hover {
  background-color: var(--category-color);
  color: var(--category-hover);
}
main.page .nav-sub .title-nav,
main.post .nav-sub .title-nav {
  padding: 30px 12px;
  border-bottom: 1px solid var(--category-gray);
  background-color: var(--category-color);
  color: var(--white);
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}
main.page section.content::after,
main.post section.content::after {
  content: "";
  display: block;
  background-image: var(--category-tochimaru);
  background-size: contain;
  background-repeat: no-repeat;
  align-self: flex-start;
  margin: 30px 0 -30px -50px;
}
main.page section.content::after {
  width: 110px;
  height: 127px;
}
.lower main.page section.content::after,
main.post section.content::after {
  width: 100px;
  height: 130px;
}
@media screen and (max-width: 1199px) {
  main.page,
  main.post {
    --main-width: 90svw;
    flex-direction: column;
  }
  main.page .nav-sub,
  main.post .nav-sub {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  main.page .nav-sub .title-nav,
  main.post .nav-sub .title-nav {
    flex-shrink: 0;
    width: 100%;
    padding: 6px 15px;
  }
  main.page .nav-sub a,
  main.post .nav-sub a {
    width: 33.333%;
    box-shadow: inset 1px 0 0 0 var(--category-gray);
  }
  main.page section.content::after {
    width: 80px;
    height: 92px;
    display: none;
  }
  main.post section.content::after {
    width: 80px;
    height: 104px;
    display: none;
  }
}
/* ==================================================================
	基本ブロック
================================================================== */
.block {
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
}
.container {
  /*
		.blockを同じグループとして複数併記する場合のラッパー
		グループとしてのスタイリング用
	*/
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  --gap: 30px;
  gap: var(--gap);
  /* gap指定　.block同士の間隔 */
  /* 縦並び指定 */
  /* 横並び指定 */
  /* 横並びの1行あたりの.block個数 */
}
.container.gap0 {
  --gap: 0;
}
.container.gap15 {
  --gap: 15px;
}
.container.gap30 {
  --gap: 30px;
}
.container.gap45 {
  --gap: 45px;
}
.container.gap60 {
  --gap: 60px;
}
.container.column,
.container[class*="column"] {
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.container.column > .block,
.container[class*="column"] > .block {
  width: 100%;
}
.container.row,
.container[class*="row"] {
  flex-direction: row;
}
.container > .block,
.container > li,
.container.row1 > .block,
.container.row1 > li {
  width: calc((100% - var(--gap) * 1) / 2);
}
.container.row2 > .block,
.container.row2 > li {
  width: calc((100% - var(--gap) * 1) / 2);
}
.container.row3 > .block,
.container.row3 > li {
  width: calc((100% - var(--gap) * 2) / 3);
}
.container.row4 > .block,
.container.row4 > li {
  width: calc((100% - var(--gap) * 3) / 4);
}
.container.row5 > .block,
.container.row5 > li {
  width: calc((100% - var(--gap) * 4) / 5);
}
ul.list,
ol.list {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 36px auto 0;
}
ul.list li,
ol.list li {
  margin-bottom: 24px;
}
ul.list li:last-child,
ol.list li:last-child {
  margin-bottom: 0;
}
.category .title-category {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding: 6px 24px;
  border-radius: 12px;
  background-color: var(--category-color);
  color: var(--white);
}
/* ==================================================================
	アンカーリンク
================================================================== */
li a {
  text-decoration: underline;
}
li a .note {
  color: inherit;
}
ul.list li a,
ol.list li a {
  text-align: left;
}
.link-more,
a.link-more,
.link-download,
a.link-download {
  display: block;
  padding-right: 45px;
  padding-left: 30px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: var(--category-color);
  color: var(--white);
  font-size: 1.8rem;
  line-height: 36px;
  text-align: center;
  position: relative;
}
.link-more:hover,
a.link-more:hover,
.link-download:hover,
a.link-download:hover {
  background-color: var(--category-light);
  transition: background-color 0.35s 0s ease;
}
.link-more::after,
a.link-more::after,
.link-download::after,
a.link-download::after {
  content: "";
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  width: 32px;
  height: 32px;
  background: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/icon-arrow-right.svg') no-repeat;
  position: absolute;
  top: 2px;
  right: 2px;
}
.link-more,
a.link-more {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.link-more.large::after,
a.link-more.large::after {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  transform: translate(50%, 25%) scale(1.125);
}
@media screen and (max-width: 1199px) {
  .link-more,
  a.link-more {
    width: 100%;
  }
}
[class*="link-"].large,
a[class*="link-"].large {
  min-height: 150px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 1.75em;
  border-radius: 12px;
  font-size: 2.1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link-download,
a.link-download {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.link-download::after,
a.link-download::after {
  transform: rotate(90deg);
}
.link-download.large,
a.link-download.large {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  padding: 18px 24px;
}
.link-download.large::after,
a.link-download.large::after {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  transform: translate(50%, 50%) scale(1.125) rotate(90deg);
}
.link-download .note,
a.link-download .note {
  color: inherit;
  text-decoration: none;
}
.link-movie,
a.link-movie {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  width: 252px;
  min-height: 120px;
  box-sizing: border-box;
  padding: 30px 30px 90px;
  border-radius: 12px;
  background-color: rgba(102, 102, 102, 0.3);
  font-size: 2.1rem;
  text-align: center;
  color: var(--black);
  position: relative;
}
.link-movie::before,
a.link-movie::before,
.link-movie::after,
a.link-movie::after {
  content: "";
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
}
.link-movie::before,
a.link-movie::before {
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-left: 21px solid var(--category-color);
  z-index: 2;
  transform: translate(-25%, -50%);
}
.link-movie::after,
a.link-movie::after {
  width: 60px;
  height: 36px;
  border-radius: 12px;
  background-color: var(--white);
  z-index: 1;
  transform: translate(-50%, -50%);
}
.link-movie:hover,
a.link-movie:hover {
  background-color: rgba(102, 102, 102, 0.5);
  transition: 0.35s 0s ease;
}
/* ==================================================================
	テーブル
================================================================== */
.content table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  background-color: var(--light-gray);
}
.content table tr:first-child th {
  background-color: var(--category-light);
}
.content table th,
.content table td {
  padding: 12px;
  background-color: var(--white);
}
.content table td a {
  display: block;
  text-decoration: underline;
}
.content table td a:hover {
  text-decoration: none;
}
/* ==================================================================
	汎用　メモ要素、連絡先表示要素など
================================================================== */
.memo,
.info,
.pdf {
  margin-top: 60px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}
.memo .title-block::before,
.info .title-block::before,
.pdf .title-block::before,
.memo .title-block::after,
.info .title-block::after,
.pdf .title-block::after {
  display: none;
}
.memo {
  /* .blockと併記
		補足説明などの汎用スタイル */
  margin-top: 36px;
  margin-bottom: 36px;
  padding: 36px;
  border-radius: 6px;
  background-color: var(--light-gray);
}
.memo .title-block {
  font-size: 1.65rem;
  font-weight: bold;
  margin-bottom: 24px;
}
section.content > div + .memo,
.memo + .memo {
  margin-top: 0;
}
.info {
  /* .blockと併記
		連絡先表記などの汎用スタイル　 */
  margin-top: 36px;
  margin-bottom: 36px;
  padding: 36px;
  background-color: var(--white);
  border-radius: 6px;
  border: 3px solid var(--category-color);
}
.info .title-block {
  font-size: 1.8rem;
  margin-bottom: 24px;
}
.info p {
  text-align: left;
}
.pdf {
  /* .blockと併記
		Adobe Acrobat ReaderのDL推奨表記の専用スタイル */
  width: 70%;
}
.pdf .title-block {
  text-align: center;
}
.pdf .download-badge {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 24px;
}
.pdf .download-badge img {
  width: auto;
}
.pdf p {
  width: 90%;
  margin: 0 auto;
}
.pdf p a {
  color: var(--category-color);
  text-decoration: underline;
}
.pdf p a:hover {
  color: var(--black);
}
/* ==================================================================
	埋め込み動画関連
================================================================== */
.movie {
  /* 埋め込み動画用ラッパー */
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.movie iframe {
  /* 埋め込み動画ブロック */
  width: 100%;
  height: 100%;
  background-color: var(--gray);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* ==================================================================
	Youtube動画のモーダル再生リンク
================================================================== */
[data-video-id] {
  display: flex;
  align-items: center;
}
[data-video-id]::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234eb7ef' viewBox='0 0 24 24'%3E%3Cpath d='M12.1,18.1L6,21.9v-3.8H4c-1.1,0-2-0.9-2-2v-12c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H12.1z M8,18.3 l3.5-2.2H20v-12H4v12h4V18.3z M15.8,10L9.7,6.4v7.2L15.8,10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
[data-video-id]:hover {
  color: var(--category-light);
}
li[data-video-id] {
  padding-left: 0;
  position: relative;
}
li[data-video-id]::before {
  content: "・";
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
}
/* ==================================================================
	
	Header
	
================================================================== */
@media screen and (max-width: 1199px) {
  .header,
  .breadcrumbs,
  .nav-global {
    width: 90vw;
  }
}
@media screen and (min-width: 1200px) {
  .header,
  .breadcrumbs,
  .nav-global {
    width: var(--main-width);
  }
}
.header {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .logo {
  width: 405px;
  -webkit-filter: drop-shadow(0 0 2px #ffffff) drop-shadow(0 0 5px #ffffff) drop-shadow(0 0 9px #ffffff);
          filter: drop-shadow(0 0 2px #ffffff) drop-shadow(0 0 5px #ffffff) drop-shadow(0 0 9px #ffffff);
}
.header .search {
  -webkit-filter: drop-shadow(0 -10px 10px rgba(89, 95, 255, 0.15));
          filter: drop-shadow(0 -10px 10px rgba(89, 95, 255, 0.15));
}
.header .search .sp-btn {
  display: none;
}
.header .search input[type="search"] {
  width: 240px;
  padding: 3px 12px;
  border-radius: 12px 0 0 12px;
  background: var(--white) url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/icon-search.png') no-repeat;
  background-position: 15px center;
  background-size: 22px;
  font-size: 1.5rem;
  line-height: 26px;
}
.header .search input[type="search"]:not(:-moz-placeholder-shown) {
  background-image: none;
}
.header .search input[type="search"]:not(:-ms-input-placeholder) {
  background-image: none;
}
.header .search input[type="search"]:focus,
.header .search input[type="search"]:not(:placeholder-shown) {
  background-image: none;
}
.header .search input[type="submit"] {
  margin-left: -6px;
  height: 32px;
  padding: 3px 18px;
  background-color: var(--red);
  border-radius: 0 12px 12px 0;
  font-size: 1.65rem;
  font-weight: bold;
  text-align: center;
  line-height: 28px;
  letter-spacing: 0.25em;
  color: var(--white);
}
.breadcrumbs {
  margin-top: 36px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}
body#home .breadcrumbs {
  display: none;
  /* ただし、プラグインの設定で非出力されている */
}
.breadcrumbs .list {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.15));
          filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.15));
}
.breadcrumbs span[property="itemListElement"] {
  display: inline-block;
  padding-right: 30px;
  background-color: var(--white);
  position: relative;
}
.breadcrumbs span[property="itemListElement"]:first-of-type {
  padding-left: 15px;
  border-radius: 12px 0 0 12px;
}
.breadcrumbs span[property="itemListElement"]:last-of-type {
  padding-right: 15px;
  border-radius: 0 12px 12px 0;
}
.breadcrumbs span[property="itemListElement"]:last-of-type::after {
  display: none;
}
.breadcrumbs span[property="itemListElement"] > a[property="item"] {
  display: inline-block;
}
.breadcrumbs span[property="itemListElement"] > a[property="item"]:hover span[property="name"] {
  color: var(--black);
  -webkit-text-decoration: underline dashed #cde6ff;
          text-decoration: underline dashed #cde6ff;
  text-underline-offset: 0.2em;
}
.breadcrumbs span[property="itemListElement"]::after {
  /* パンくず矢印 */
  content: "";
  display: inline-block;
  width: 27px;
  height: 27px;
  border: 2px solid var(--blue-gray);
  border-width: 2px 2px 0 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
}
.breadcrumbs span[property="name"] {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;
  line-height: 35px;
  font-size: 1.5rem;
  color: var(--gray);
}
.nav-global {
  margin-top: 24px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  --gap: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap);
}
body#home .nav-global {
  --gap: 60px;
}
body#home .nav-global a[class^='link-'] {
  width: 255px;
  height: 160px;
  -webkit-filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.15));
          filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.15));
  display: flex;
  justify-content: center;
  align-items: center;
}
body#home .nav-global a.link-about {
  height: auto;
}
body:not(#home) .nav-global {
  --gap: 0;
  border-radius: 12px;
  box-shadow: 0 -10px 30px 0 rgba(89, 95, 255, 0.15);
}
body:not(#home) .nav-global a[class^='link-'] {
  height: 33px;
  box-sizing: content-box;
  padding: 15px 0 18px;
  text-align: center;
  text-indent: -22px;
  position: relative;
}
body:not(#home) .nav-global a[class^='link-'] img {
  /* width: auto; */
  /* max-width: 70%; */
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
body:not(#home) .nav-global a[class^='link-']:hover {
  opacity: 1;
  transition: background-color 0.35s 0s ease-out;
}
body:not(#home) .nav-global a[class^='link-']:hover img {
  -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  transition: -webkit-filter 0.35s 0s ease;
  transition: filter 0.35s 0s ease;
  transition: filter 0.35s 0s ease, -webkit-filter 0.35s 0s ease;
}
body:not(#home) .nav-global a[class^='link-']::after {
  content: "";
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  width: 22px;
  height: 22px;
  background: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/icon-arrow-right.svg') no-repeat;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transform: translate(0, -50%);
}
body:not(#home) .nav-global a.link-news {
  background-color: var(--news-color);
}
body:not(#home) .nav-global a.link-news:hover {
  background-color: var(--news-light);
}
body:not(#home) .nav-global a.link-tairyoku {
  background-color: var(--tairyoku-color);
}
body:not(#home) .nav-global a.link-tairyoku:hover {
  background-color: var(--tairyoku-light);
}
body:not(#home) .nav-global a.link-shindan {
  background-color: var(--shindan-color);
}
body:not(#home) .nav-global a.link-shindan:hover {
  background-color: var(--shindan-light);
}
body:not(#home) .nav-global a.link-challenge {
  background-color: var(--challenge-color);
}
body:not(#home) .nav-global a.link-challenge:hover {
  background-color: var(--challenge-light);
}
body:not(#home) .nav-global a.link-genkikids {
  background-color: var(--genkikids-color);
}
body:not(#home) .nav-global a.link-genkikids:hover {
  background-color: var(--genkikids-light);
}
body:not(#home) .nav-global a.link-exercise {
  background-color: var(--exercise-color);
}
body:not(#home) .nav-global a.link-exercise:hover {
  background-color: var(--exercise-light);
}
body:not(#home) .nav-global a.link-movies {
  background-color: var(--movies-color);
}
body:not(#home) .nav-global a.link-movies:hover {
  background-color: var(--movies-light);
}
body:not(#home) .nav-global a.link-link {
  background-color: var(--link-color);
}
body:not(#home) .nav-global a.link-link:hover {
  background-color: var(--link-light);
}
body:not(#home) .nav-global a.link-about {
  display: none;
}
@media screen and (max-width: 1199px) {
  .header .logo {
    width: 50%;
    padding-bottom: 2.5vw;
  }
  body#home .nav-global {
    --gap: 24px;
    margin-bottom: 24px;
  }
  body#home .nav-global a[class^='link-'] {
    width: calc((100% - (var(--gap) * 2)) / 3);
    height: auto;
  }
  body:not(#home) .nav-global a[class^='link-'] {
    width: 50%;
    height: 27px;
    padding: 9px 0 12px;
  }
  body:not(#home) .nav-global a.link-news {
    border-radius: 12px 0 0 0;
  }
  body:not(#home) .nav-global a.link-tairyoku {
    border-radius: 0 12px 0 0;
  }
  body:not(#home) .nav-global a.link-movies {
    text-indent: -37px;
  }
  body:not(#home) .nav-global a.link-exercise {
    text-indent: -37px;
  }
  body:not(#home) .nav-global a.link-genkikids {
    text-indent: -37px;
    border-radius: 0 0 0 12px;
  }
  body:not(#home) .nav-global a.link-link {
    text-indent: -37px;
    border-radius: 0 0 12px 0;
  }
}
@media screen and (min-width: 1200px) {
  body:not(#home) .nav-global a.link-news {
    width: 284px;
    border-radius: 12px 0 0 0;
  }
  body:not(#home) .nav-global a.link-tairyoku {
    width: 370px;
  }
  body:not(#home) .nav-global a.link-shindan {
    width: 230px;
  }
  body:not(#home) .nav-global a.link-movies {
    width: 316px;
    border-radius: 0 12px 0 0;
  }
  body:not(#home) .nav-global a.link-exercise {
    width: 280px;
    border-radius: 0 0 0 12px;
  }
  body:not(#home) .nav-global a.link-challenge {
    width: 310px;
    padding: 10px 0 23px;
  }
  body:not(#home) .nav-global a.link-genkikids {
    width: 310px;
  }
  body:not(#home) .nav-global a.link-link {
    width: 300px;
    border-radius: 0 0 12px 0;
  }
}
/* ==================================================================
	
	Footer
	
================================================================== */
.footer {
  padding-top: 60px;
  width: 800px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}
.footer .link-top {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  width: 137px;
  height: 68px;
  margin-left: auto;
  margin-right: -200px;
  -webkit-filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.2));
          filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.2));
}
.footer .nav-footer {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0 30px;
}
.footer .nav-footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 60px) / 3);
  padding: 3px 0;
  border-radius: 30px;
  background-color: var(--green);
  color: var(--white);
  font-size: 1.5rem;
}
.footer .nav-footer a:hover {
  background-color: var(--white);
  color: var(--green);
}
.footer .copyright {
  margin: 36px 0 12px;
  font-size: 1.35rem;
  text-align: center;
  color: var(--white);
}
@media screen and (max-width: 1199px) {
  .footer {
    width: 90vw;
  }
  .footer .link-top {
    margin-right: auto;
  }
}
:root {
  --news-color: var(--red);
  --news-light: var(--light-red);
  --news-gray: #f4d2d2;
  --tairyoku-color: var(--orange);
  --tairyoku-light: var(--light-orange);
  --tairyoku-gray: #f9f4ee;
  --shindan-color: var(--yellow);
  --shindan-light: var(--light-yellow);
  --shindan-gray: #fbfaf5;
  --movies-color: var(--green);
  --movies-light: var(--light-green);
  --movies-gray: #d2e9c5;
  --exercise-color: var(--sky);
  --exercise-light: var(--light-sky);
  --exercise-gray: #edf5f7;
  --challenge-color: var(--blue);
  --challenge-light: var(--light-blue);
  --challenge-gray: #ffffff;
  --genkikids-color: var(--violet);
  --genkikids-light: var(--light-violet);
  --genkikids-gray: #ffffff;
  --link-color: var(--pink);
  --link-light: var(--light-pink);
  --link-gray: #ffffff;
}
@media screen and (max-width: 1199px) {
  body {
    background: none;
  }
  body::before {
    content: "";
    display: block;
    /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
    width: 100%;
    height: 100%;
    background-image: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg@sp.jpg');
    background-position: 10% center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
/* ==================================================================
	
	404 
	@404.php
	
================================================================== */
#error404 {
  --category-color: var(--red);
}
#error404 .content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#error404 .title-content {
  font-size: 2.1rem;
}
/* ==================================================================
	
	Home 
	@index.php
	
================================================================== */
@media screen and (min-width: 780px) {
  #home .news {
    width: 800px;
  }
}
#home .news {
  margin-top: 60px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding: 0 51px 42px;
  background-color: var(--white);
  border-radius: 12px;
  -webkit-filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.15));
          filter: drop-shadow(0 -10px 20px rgba(89, 95, 255, 0.15));
}
#home .news .title-news {
  text-align: center;
  padding: 15px 0;
  border-bottom: 2px solid var(--red);
  margin-bottom: 15px;
}
#home .news .title-news img {
  width: 146px;
}
#home .news .container {
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 42px;
}
#home .news .block {
  display: flex;
  flex-flow: row nowrap;
}
#home .news .block .date {
  width: 24%;
}
#home .news .block .topic {
  width: 76%;
  color: var(--red);
  font-weight: bold;
}
#home .news .link-more {
  background-color: var(--red);
  color: var(--white);
}
/* ==================================================================
	お知らせ（新着情報）
================================================================== */
#news {
  --category-color: var(--news-color);
  --category-light: var(--news-light);
  --category-gray: var(--news-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-red.webp');
  /* お知らせ（news）投稿 */
}
#news .block {
  margin-bottom: 60px;
}
#news .block:last-of-type {
  margin-bottom: 0;
}
#news .block .date {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--light-red);
  margin-bottom: 12px;
}
#news .block .topic {
  font-size: 2.1rem;
  font-weight: bold;
  color: var(--category-color);
}
#news .pagination {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px;
  border-radius: 12px;
  margin: 24px auto 0;
  background-color: var(--category-color);
  position: relative;
  z-index: 11;
}
#news .pagination .page-num {
  display: block;
  font-size: 1.2rem;
  color: var(--white);
  text-align: center;
  letter-spacing: 0.1em;
}
#news .pagination .pagers {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
#news .pagination .pager {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--category-color);
  text-align: center;
  letter-spacing: 0;
}
#news .pagination .pager.current {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--white);
  color: var(--white);
}
#news .pagination a.pager:hover {
  opacity: 0.9;
  color: var(--category-color);
}
#news .pagination .prev,
#news .pagination .next {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 12px;
  font-size: 1.35rem;
  border-radius: 6px;
}
#news main.page .nav-sub,
#news main.post .nav-sub {
  display: none;
}
#news main.post {
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru01-red.webp');
}
#news main.post .content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
#news main.post .container {
  display: block;
}
#news main.post .container img[class*="wp-image"] {
  display: block;
  width: 80%;
  margin: 36px auto;
}
#news main.post .container img[class*="wp-image"]:first-child {
  margin-top: 0;
}
#news main.post .container img[class*="wp-image"]:last-child {
  margin-bottom: 0;
}
#news main.post .link-more {
  margin-top: 60px;
}
/* ==================================================================
	栃木県児童生徒の体力ゾーン
================================================================== */
#tairyoku {
  --category-color: var(--tairyoku-color);
  --category-light: var(--tairyoku-light);
  --category-gray: var(--tairyoku-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-orange.webp');
}
#tairyoku .tairyoku,
#tairyoku .awards,
#tairyoku .documents {
  width: 100%;
}
#tairyoku .tairyoku h2[class^="title-"],
#tairyoku .awards h2[class^="title-"],
#tairyoku .documents h2[class^="title-"] {
  display: flex;
  justify-content: center;
  align-items: center;
}
#tairyoku .tairyoku h2[class^="title-"]::before,
#tairyoku .awards h2[class^="title-"]::before,
#tairyoku .documents h2[class^="title-"]::before,
#tairyoku .tairyoku h2[class^="title-"]::after,
#tairyoku .awards h2[class^="title-"]::after,
#tairyoku .documents h2[class^="title-"]::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: var(--category-light);
}
#tairyoku .tairyoku .container,
#tairyoku .awards .container,
#tairyoku .documents .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px 120px;
}
#tairyoku .tairyoku a.block,
#tairyoku .awards a.block,
#tairyoku .documents a.block {
  width: 200px;
  text-align: center;
}
#tairyoku .tairyoku a.block > img,
#tairyoku .awards a.block > img,
#tairyoku .documents a.block > img {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  border-radius: 12px;
  border: 3px solid var(--category-color);
  background-color: var(--white);
}
#tairyoku .tairyoku a.block:hover img,
#tairyoku .awards a.block:hover img,
#tairyoku .documents a.block:hover img {
  opacity: 0.8;
  transition: opacity 0.35s 0s ease;
}
#tairyoku .tairyoku a.block > h3,
#tairyoku .awards a.block > h3,
#tairyoku .documents a.block > h3 {
  margin: 12px 0;
  font-size: 1.8rem;
  color: var(--black);
}
#tairyoku .tairyoku a.block .link-more,
#tairyoku .awards a.block .link-more,
#tairyoku .documents a.block .link-more {
  width: 100%;
  text-indent: -15px;
}
#tairyoku .past {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 72px auto 0;
  text-align: center;
}
#tairyoku.lower {
  /* body.lower 下部階層ページのスタイル */
}
#tairyoku.lower .content > p {
  width: 100%;
  margin-bottom: 36px;
}
#tairyoku.lower ul.list.links,
#tairyoku.lower ol.list.links {
  list-style: none;
}
#tairyoku.lower ul.list.links li,
#tairyoku.lower ol.list.links li {
  margin: 0;
}
#tairyoku.lower ul.list.ranking,
#tairyoku.lower ol.list.ranking {
  min-width: 500px;
  counter-reset: number;
}
#tairyoku.lower ul.list.ranking li,
#tairyoku.lower ol.list.ranking li {
  background-color: var(--category-light);
  font-size: 2.1rem;
  color: var(--white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
}
#tairyoku.lower ul.list.ranking li::before,
#tairyoku.lower ol.list.ranking li::before {
  counter-increment: number;
  content: counter(number);
  width: 45px;
  height: 45px;
  background-color: var(--category-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.65rem;
  text-align: center;
  color: var(--white);
}
#tairyoku.lower table {
  margin-bottom: 60px;
}
/* ==================================================================
	体力診断ゾーン
================================================================== */
#shindan {
  --category-color: var(--shindan-color);
  --category-light: var(--shindan-light);
  --category-gray: var(--shindan-gray);
  --category-hover: var(--black);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-yellow.webp');
}
#shindan .content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
#shindan > .block {
  width: 100%;
}
#shindan .block {
  text-align: center;
}
#shindan .container .title-container,
#shindan .container > .note {
  width: 100%;
  flex-shrink: 0;
}
#shindan .container .title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#shindan .container .title-container::before,
#shindan .container .title-container::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: var(--category-light);
}
#shindan .container > .note {
  margin-top: 24px;
  text-align: center;
}
#shindan h3.title-block {
  color: var(--black);
}
#shindan.lower {
  /* body.lower 下部階層ページのスタイル */
}
#shindan.lower .content > p {
  width: 100%;
  text-align: center;
}
#shindan.lower .result {
  margin-top: 45px;
}
#shindan.lower .result .title-result {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
#shindan.lower .result .title-result::before,
#shindan.lower .result .title-result::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: var(--category-light);
}
#shindan.lower .result .link-movie {
  height: 200px;
  margin: 0 auto;
}
#shindan.lower .result .link-movie::before,
#shindan.lower .result .link-movie::after {
  top: 55%;
}
#shindan.lower .form {
  margin-top: 36px;
}
#shindan.lower .form .title-form {
  color: var(--category-color);
  text-align: center;
}
#shindan.lower .form > p {
  text-align: center;
}
#shindan.lower form {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
#shindan.lower form .input {
  padding: 24px 0 0;
  width: 100%;
}
#shindan.lower form .input#user-grade,
#shindan.lower form .input#user-club {
  width: calc(60% - 15px);
}
#shindan.lower form .input#user-class,
#shindan.lower form .input#user-id {
  width: calc(40% - 15px);
}
#shindan.lower .key {
  font-size: 1.8rem;
}
#shindan.lower .value {
  padding: 12px 0 0;
  font-size: 1.8rem;
  position: relative;
}
#shindan.lower .value .error {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 24px;
  background-color: var(--accent);
  color: var(--white);
  position: absolute;
  top: 0;
  right: calc(100% + 24px);
}
#shindan.lower .value .error:after {
  content: "";
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-left: 12px solid var(--accent);
  position: absolute;
  top: 50%;
  right: -21px;
  transform: translateY(-50%);
}
#shindan.lower .input.must .key::before {
  content: "※";
  color: var(--accent);
  margin-right: 0.5em;
}
#shindan.lower input[type="text"],
#shindan.lower input[type="email"],
#shindan.lower textarea {
  border-radius: 5px;
  border: 1px solid var(--gray);
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
}
#shindan.lower textarea {
  resize: vertical;
}
#shindan.lower select {
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid var(--gray);
}
#shindan.lower .checkbox {
  padding-top: 1px;
  margin-bottom: 24px;
}
#shindan.lower .checkbox:last-of-type {
  margin-bottom: 0;
}
#shindan.lower .horizontal-item {
  width: 100%;
  text-align: center;
}
#shindan.lower .horizontal-item + .horizontal-item {
  margin-left: 0;
}
#shindan.lower .zip {
  width: 100%;
  text-align: center;
}
#shindan.lower .zip input {
  width: auto;
}
#shindan.lower .submit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 36px auto 0;
  position: relative;
}
#shindan.lower .submit input[type="submit"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 6px;
  background-color: var(--category-color);
  color: var(--white);
  font-size: 1.8rem;
}
#shindan.lower .submit input[type="submit"]:hover {
  opacity: 0.5;
  transition: opacity 0.5s 0s ease;
}
@media screen and (max-width: 1199px) {
  #shindan .container .title-container {
    margin-bottom: 0;
  }
}
/* ==================================================================
	チャレンジランキングゾーン
================================================================== */
#challenge {
  --category-color: var(--challenge-color);
  --category-light: var(--challenge-light);
  --category-gray: var(--challenge-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-green.webp');
}
#challenge .about .link-more {
  width: 100%;
  margin-bottom: 12px;
}
#challenge .about .container {
  margin-top: 36px;
}
#challenge .about .container .block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#challenge .challenges .category {
  margin-bottom: 90px;
}
#challenge .challenges .category.personal {
  margin-bottom: 0;
}
#challenge .challenges .category .title-category {
  margin-bottom: 60px;
  color: var(--white);
}
#challenge .challenges .container {
  justify-content: flex-start;
}
#challenge .challenges .container .block {
  text-align: center;
}
#challenge .challenges .container .text {
  padding: 12px 0;
}
#challenge .challenges .container .title-text,
#challenge .challenges .container .lead {
  margin-bottom: 15px;
}
#challenge.single {
  /* body.single チャレンジランキング投稿コンテンツのスタイル */
}
#challenge.single .tochimaru-label {
  width: 100px;
  top: 10px;
  right: 10px;
}
#challenge.single .content > p {
  width: 100%;
  text-align: center;
}
#challenge.single .block {
  width: 100%;
  margin: 0 auto 60px;
}
#challenge.single .billboard {
  margin-bottom: 24px;
}
#challenge.single .text {
  padding: 36px;
  border-radius: 6px;
  margin-bottom: 24px;
  background-color: var(--light-gray);
}
#challenge.single .text .title-text {
  margin-bottom: 24px;
}
#challenge.single .text p:last-child,
#challenge.single .text:last-child {
  margin-bottom: 0;
}
#challenge.single .overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
#challenge.single .overview .lead {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
}
#challenge.single .volume {
  width: calc((100% - 20px) / 2);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 6px;
  background-color: var(--category-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
#challenge.single .volume .key {
  font-size: 1.5rem;
  color: var(--white);
  margin-right: 15px;
}
#challenge.single .volume .value {
  flex-grow: 1;
  padding: 6px 15px;
  border-radius: 6px;
  background-color: var(--white);
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--black);
}
#challenge.single .movie {
  margin: 0 auto 60px;
}
/* ==================================================================
	とちぎ元気キッズゾーン
================================================================== */
#genkikids {
  --category-color: var(--genkikids-color);
  --category-light: var(--genkikids-light);
  --category-gray: var(--genkikids-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-sky.webp');
  /* ==================================================================
	jquery-modal-video
	================================================================== */
}
#genkikids .content > .block {
  text-align: left;
}
#genkikids #playpark-lead {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 150px;
}
#genkikids #playpark-lead > a {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}
#genkikids #playpark-lead > a:hover .link-more {
  background-color: var(--category-light);
}
#genkikids #playpark-lead > a:hover img.thumbnail {
  -webkit-filter: brightness(0.75);
          filter: brightness(0.75);
}
#genkikids #playpark-lead span.link-more.large {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-height: unset;
  padding-inline: 60px;
  padding-block: 15px;
  position: absolute;
  left: 50%;
  bottom: 0;
  translate: -50% 75%;
  z-index: 1;
  transition: 0.35s 0s ease;
}
#genkikids #playpark-lead span.link-more.large::after {
  top: 50%;
  right: 15px;
  transform: translate(0, -50%) scale(1.125);
}
#genkikids #playpark-lead img.thumbnail {
  width: 600px;
  border-radius: 12px;
  transition: 0.35s 0s ease;
}
#genkikids #playpark-lead img.tochimaru {
  width: 120px;
  position: absolute;
  left: 0;
  bottom: 0;
  translate: -10% 70%;
  z-index: 2;
  pointer-events: none;
}
#genkikids .container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 60px 120px;
}
#genkikids .container a.block {
  width: 200px;
  text-align: center;
}
#genkikids .container a.block > img {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  border-radius: 12px;
  border: 3px solid var(--category-color);
  background-color: var(--white);
}
#genkikids .container a.block:hover img {
  opacity: 0.8;
  transition: opacity 0.35s 0s ease;
}
#genkikids .container a.block > h3 {
  margin: 12px 0;
  font-size: 1.8rem;
  color: var(--black);
}
#genkikids .container a.block .link-more {
  width: 100%;
  text-indent: -15px;
}
#genkikids.lower {
  /* body.lower 下部階層ページのスタイル */
}
#genkikids #supportprogram table tr:nth-child( n + 1 ) th {
  text-align: center;
}
#genkikids #supportprogram table th.age {
  width: 8%;
}
#genkikids .title-category span[data-video-id],
#genkikids .title-container span[data-video-id] {
  font-weight: 700;
}
#genkikids .title-category [data-video-id]::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 24 24'%3E%3Cpath d='M12.1,18.1L6,21.9v-3.8H4c-1.1,0-2-0.9-2-2v-12c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H12.1z M8,18.3 l3.5-2.2H20v-12H4v12h4V18.3z M15.8,10L9.7,6.4v7.2L15.8,10z'/%3E%3C/svg%3E");
}
#genkikids #gymnastics {
  font-family: -apple-system, blinkMacSystemFont, "Helvetica Neue", Roboto, "Segoe UI", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.wf-active #genkikids #gymnastics {
  font-family: 'M PLUS Rounded 1c';
  /* include weight: 400, 700 */
}
#genkikids #gymnastics .category#workshop p:first-of-type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#genkikids #gymnastics .title-category {
  margin-left: 0;
  margin-bottom: 36px;
  color: var(--white);
  font-weight: 700;
}
#genkikids #gymnastics .container {
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 12px 1.5em;
  margin-bottom: 60px;
  counter-reset: number 0;
}
#genkikids #gymnastics .container .title-container {
  width: 100%;
  flex-shrink: 0;
  font-size: 1.8rem;
  font-weight: 700;
}
#genkikids #gymnastics .title-category span[data-video-id],
#genkikids #gymnastics .title-container span[data-video-id] {
  font-weight: 700;
}
#genkikids #gymnastics ul.container {
  display: block;
  padding-left: 20px;
}
#genkikids #gymnastics [data-video-id] {
  padding: 6px 9px;
  border-radius: 6px;
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.35s 0s ease;
  display: flex;
  align-items: center;
}
#genkikids #gymnastics [data-video-id]::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234eb7ef' viewBox='0 0 24 24'%3E%3Cpath d='M12.1,18.1L6,21.9v-3.8H4c-1.1,0-2-0.9-2-2v-12c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H12.1z M8,18.3 l3.5-2.2H20v-12H4v12h4V18.3z M15.8,10L9.7,6.4v7.2L15.8,10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#genkikids #gymnastics [data-video-id]:hover {
  color: var(--category-light);
}
#genkikids #gymnastics .title-category [data-video-id]::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 24 24'%3E%3Cpath d='M12.1,18.1L6,21.9v-3.8H4c-1.1,0-2-0.9-2-2v-12c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H12.1z M8,18.3 l3.5-2.2H20v-12H4v12h4V18.3z M15.8,10L9.7,6.4v7.2L15.8,10z'/%3E%3C/svg%3E");
}
#genkikids #gymnastics li[data-video-id] {
  padding-left: 0;
  position: relative;
}
#genkikids #gymnastics li[data-video-id]::before {
  content: "・";
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
}
#genkikids #gymnastics #workshop p {
  font-size: 1.8rem;
}
#genkikids .modal-video-body {
  max-width: 80vw;
}
main#playarea01 {
  --playarea-color: #F72A2A;
}
main#playarea02 {
  --playarea-color: #C134E3;
}
main#playarea03 {
  --playarea-color: #009CF0;
}
main#playarea04 {
  --playarea-color: #FCB900;
}
main#playarea05 {
  --playarea-color: #45BF08;
}
main:is(#playarea01, #playarea02, #playarea03, #playarea04, #playarea05) .title-content {
  margin-bottom: 24px;
}
body#genkikids:has(.block.is_active) {
  overflow: clip;
}
body#genkikids:has(.block.is_active)::after {
  content: "";
  display: block;
  width: 100svw;
  height: 100svh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 999;
}
body#genkikids section.content::after {
  display: none;
}
body#genkikids .playpark-map {
  position: relative;
}
body#genkikids .playpark-map a,
body#genkikids .playpark-map img.fountain,
body#genkikids .playpark-map span.nav-greeting {
  position: absolute;
}
body#genkikids .playpark-map a.nav-playarea01 {
  width: calc(524 / 1050 * 100%);
  height: calc(325 / 750 * 100%);
  left: 0;
  top: calc(187.7298 / 750 * 100%);
  z-index: 2;
  -webkit-clip-path: url(#mask-playarea01);
          clip-path: url(#mask-playarea01);
}
body#genkikids .playpark-map a.nav-playarea02 {
  width: calc(501 / 1050 * 100%);
  height: calc(234 / 750 * 100%);
  left: calc(434.0872 / 1050 * 100%);
  top: calc(173.7114 / 750 * 100%);
  z-index: 1;
  -webkit-clip-path: url(#mask-playarea02);
          clip-path: url(#mask-playarea02);
}
body#genkikids .playpark-map a.nav-playarea03 {
  width: calc(462 / 1050 * 100%);
  height: calc(393 / 750 * 100%);
  left: calc(591 / 1050 * 100%);
  top: calc(225.5718 / 750 * 100%);
  z-index: 3;
  -webkit-clip-path: url(#mask-playarea03);
          clip-path: url(#mask-playarea03);
}
body#genkikids .playpark-map img.fountain {
  width: calc(101.5864 / 1050 * 100%);
  left: calc(474 / 1050 * 100%);
  top: calc(334 / 750 * 100%);
  z-index: 4;
}
body#genkikids .playpark-map a.nav-playarea04 {
  width: calc(492 / 1050 * 100%);
  height: calc(282 / 750 * 100%);
  left: 0;
  bottom: 0;
  z-index: 5;
  -webkit-clip-path: url(#mask-playarea04);
          clip-path: url(#mask-playarea04);
}
body#genkikids .playpark-map a.nav-playarea05 {
  width: calc(586 / 1050 * 100%);
  height: calc(283 / 750 * 100%);
  right: 0;
  bottom: 0;
  z-index: 6;
  -webkit-clip-path: url(#mask-playarea05);
          clip-path: url(#mask-playarea05);
}
body#genkikids .playpark-map span.nav-greeting {
  width: calc(172.9073 / 1050 * 100%);
  left: calc(863 / 1050 * 100%);
  top: calc(25 / 750 * 100%);
  z-index: 1;
}
@media (hover: hover) {
  body#genkikids .playpark-map a,
  body#genkikids .playpark-map span.nav-greeting {
    cursor: pointer;
    transition: 0.3s 0s ease;
  }
  body#genkikids .playpark-map:has(a:hover) a {
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
  }
  body#genkikids .playpark-map a:hover {
    -webkit-filter: brightness(1) !important;
            filter: brightness(1) !important;
    scale: 1.03;
    transform-origin: center bottom;
  }
  body#genkikids .playpark-map span.nav-greeting:hover {
    scale: 1.1;
  }
}
body#genkikids .playarea .block {
  width: calc(var(--main-width) - 60px);
  border-radius: 0 0 30px 30px;
  background: var(--white);
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
body#genkikids .playarea .block:not(.is_active) {
  opacity: 0;
  pointer-events: none;
}
body#genkikids .playarea .block:is(.is_active) {
  opacity: 1;
  pointer-events: auto;
  z-index: 1000;
}
body#genkikids .playarea .block .nav-close {
  top: 0;
  right: 0;
  translate: 150% 0;
  z-index: 1;
}
body#genkikids .playarea iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
body#genkikids .playarea .title-block {
  display: block;
  margin-block: 24px 21px;
  color: var(--playarea-color);
}
body#genkikids .playarea .title-block::after {
  display: none;
}
body#genkikids .playarea .title-block + p {
  width: 60%;
  margin-inline: auto;
  margin-bottom: 24px;
}
body#genkikids .playarea-map {
  position: relative;
  overflow-x: clip;
}
body#genkikids .playarea-map a {
  width: calc(150 / 1050 * 100%);
  border-radius: 10px;
  border: 5px solid var(--white);
  -webkit-filter: drop-shadow(6px 6px 0 var(--black));
          filter: drop-shadow(6px 6px 0 var(--black));
  display: flex;
  flex-direction: column;
  font-size: min(calc(12 / 1050 * 100vw), 12px);
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: -0.05ic;
  text-align: center;
  cursor: pointer;
  position: absolute;
}
@media (hover: hover) {
  body#genkikids .playarea-map a:hover {
    border: 5px solid var(--playarea-color);
  }
}
body#genkikids .playarea-map a.is_played,
body#genkikids .playarea-map a:visited {
  border-color: var(--playarea-color);
}
body#genkikids .playarea-map img.thumbnail {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  height: auto;
  aspect-ratio: 15 / 11;
  border-radius: 10px 10px 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}
body#genkikids .playarea-map img.thumbnail + span {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5lh 0.5ic;
  background: #FFEBD7;
}
body#genkikids .playarea-map > a {
  left: calc(var(--left) / 1050 * 100%);
}
body#genkikids #playarea01 .playarea-map > a {
  top: calc(var(--top) / 750 * 100%);
}
body#genkikids #playarea02 .playarea-map > a {
  top: calc(var(--top) / 750 * 100%);
}
body#genkikids #playarea03 .playarea-map > a {
  top: calc(var(--top) / 1000 * 100%);
}
body#genkikids #playarea04 .playarea-map > a {
  top: calc(var(--top) / 800 * 100%);
}
body#genkikids #playarea05 .playarea-map > a {
  top: calc(var(--top) / 750 * 100%);
}
/* ==================================================================
	体力アップ動画ゾーン
================================================================== */
#movies {
  --category-color: var(--movies-color);
  --category-light: var(--movies-light);
  --category-gray: var(--movies-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-blue.webp');
}
#movies .content {
  text-align: center;
}
#movies .selector {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 6px 24px;
  border-radius: 12px;
  background-color: var(--category-color);
}
#movies .selector .label {
  color: var(--white);
}
#movies .selector select {
  min-width: 150px;
  padding: 6px 12px;
  border-radius: 12px;
  color: var(--white);
  background-color: var(--category-light);
}
#movies .selector select.active {
  color: var(--black);
  background-color: var(--white);
}
#movies .title-result {
  display: flex;
  justify-content: center;
  align-items: center;
}
#movies .title-result::before,
#movies .title-result::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: var(--category-light);
}
#movies.single {
  /* body.single チャレンジランキング投稿コンテンツのスタイル */
}
#movies.single .tochimaru-label {
  width: 100px;
  top: 10px;
  right: 10px;
}
@media screen and (max-width: 1199px) {
  #movies .content {
    padding: 25px 6vw 60px;
  }
  #movies .selector {
    padding-bottom: 12px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 15px;
  }
  #movies .selector .label {
    display: block;
    width: 100%;
    flex-shrink: 0;
  }
  #movies select {
    margin-bottom: 15px;
  }
  #movies .container {
    flex-direction: column;
  }
  #movies .container .block {
    width: 100%;
  }
}
/* ==================================================================
	運動遊びゾーン
================================================================== */
#exercise {
  --category-color: var(--exercise-color);
  --category-light: var(--exercise-light);
  --category-gray: var(--exercise-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-violet.webp');
}
#exercise .result .title-result + p {
  text-align: center;
  margin-bottom: 36px;
}
#exercise .result .container {
  position: relative;
  z-index: auto;
}
#exercise .result .block {
  transition: 0.2s 0s ease;
}
/* ==================================================================
	その他(関連リンク等)
================================================================== */
#link {
  --category-color: var(--link-color);
  --category-light: var(--link-light);
  --category-gray: var(--link-gray);
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-pink.webp');
}
#link .content {
  text-align: center;
}
#link h3 {
  color: var(--category-hover);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 12px;
  border-radius: 6px;
  background-color: var(--category-color);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 24px;
}
#link .list {
  margin-top: 36px;
}
#link .list li {
  margin-bottom: 15px;
}
#link .list a {
  display: block;
}
/* ==================================================================
	お問い合わせ
================================================================== */
#contact {
  --category-color: #4a3117;
  --category-light: #4a3117;
  --category-gray: #807d7a;
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-yellow.webp');
}
#contact .content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
#contact .title-content + p {
  text-align: center;
}
#contact .mw_wp_form {
  width: 70%;
}
#contact form .input {
  padding: 24px 0 0;
  width: 100%;
}
#contact .key {
  font-size: 1.8rem;
}
#contact .value {
  padding: 12px 0 0;
  font-size: 1.8rem;
  position: relative;
}
#contact .value .error {
  width: 200px;
  padding: 12px 24px;
  border-radius: 6px;
  background-color: var(--accent);
  color: var(--white);
  position: absolute;
  top: 50%;
  right: calc(100% + 16px);
  transform: translateY(-50%);
}
#contact .value .error:after {
  content: "";
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-left: 12px solid var(--accent);
  position: absolute;
  top: 50%;
  right: -19px;
  transform: translateY(-50%);
}
#contact .input.must .key::before {
  content: "※";
  color: var(--accent);
  margin-right: 0.5em;
}
#contact input[type="text"],
#contact input[type="email"],
#contact textarea {
  border-radius: 5px;
  border: 1px solid var(--gray);
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
}
#contact textarea {
  resize: vertical;
}
#contact select {
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid var(--gray);
}
#contact .mwform-checkbox-field {
  padding-top: 1px;
  margin-bottom: 24px;
}
#contact .mwform-checkbox-field:last-of-type {
  margin-bottom: 0;
}
#contact .horizontal-item {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
}
#contact .mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}
#contact .mwform-zip-field {
  display: block;
  /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
}
#contact .mwform-zip-field input {
  width: auto;
}
#contact .submit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 36px auto 0;
  position: relative;
}
#contact .submit input[type="submit"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 6px;
  background-color: var(--green);
  color: var(--white);
  font-size: 1.8rem;
}
#contact .submit input[type="submit"]:hover {
  opacity: 0.5;
  transition: opacity 0.5s 0s ease;
}
@media screen and (max-width: 1199px) {
  #contact .mw_wp_form {
    width: 100%;
  }
  #contact .key {
    font-size: 1.5rem;
  }
  #contact .value {
    padding-top: 0;
  }
  #contact input[type="text"]::-moz-placeholder, #contact input[type="email"]::-moz-placeholder {
    font-size: 1.35rem;
  }
  #contact input[type="text"]:-ms-input-placeholder, #contact input[type="email"]:-ms-input-placeholder {
    font-size: 1.35rem;
  }
  #contact input[type="text"]::-ms-input-placeholder, #contact input[type="email"]::-ms-input-placeholder {
    font-size: 1.35rem;
  }
  #contact input[type="text"]::placeholder,
  #contact input[type="email"]::placeholder {
    font-size: 1.35rem;
  }
  #contact textarea {
    height: 200px;
  }
  #contact .mwform-zip-field {
    display: block;
    /*
		基本的な囲みの要素
		個別の設定は、body#[permalink]以下で指定
	*/
  }
}
/* ==================================================================
	一般固定ページ
================================================================== */
#about {
  --category-color: #4a3117;
  --category-light: #4a3117;
  --category-gray: #807d7a;
  --category-hover: var(--white);
  --category-tochimaru: url('https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/bg-tochimaru02-yellow.webp');
}
#about .title-content {
  margin-bottom: 24px;
}
#about .title-content img {
  height: 86px;
}
#about .content .logo {
  display: block;
  width: 400px;
  margin-bottom: 60px;
}
#about h2 {
  margin-top: 60px;
}
